import { useContextSelector } from 'use-context-selector'

import { PartnershipContext } from '@Context/partnership-context'

const usePartnership = () => {
  const partnership = useContextSelector(
    PartnershipContext,
    (context) => context?.partnership
  )

  return partnership
}

export default usePartnership
