import { SplideProps } from '@splidejs/react-splide'
import { Splide } from '@splidejs/react-splide'
import { ClassValue } from 'clsx'
import ArrowIcon from 'public/images/arrow-right.svg'
import { forwardRef, ReactNode } from 'react'

import { cn } from '@Shared/helpers/util'

import '@splidejs/react-splide/css'

interface SwiperProps extends SplideProps {
  children: ReactNode[]
  shadows?: boolean
  classes?: {
    track?: ClassValue[] | string
    list?: ClassValue[] | string
    slide?: ClassValue[] | string
  }
}

const Swiper = forwardRef<Splide, SwiperProps>(
  (
    { children, classes, className, options, shadows = true, ...props },
    ref
  ) => {
    const shadowsCSS = [
      // BEFORE
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:content-[""]',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:top-0',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:bottom-0',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:block',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:w-[50px]',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:absolute',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:z-[2]',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:sm:w-[240px]',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:before:pointer-events-none',
      // GRADIENT TO LEFT
      '[&.is-overflow:has(button.swiper-arrow-left:not(:disabled))]:before:bg-gradient-to-l',
      '[&.is-overflow:has(button.swiper-arrow-left:not(:disabled))]:before:from-transparent',
      '[&.is-overflow:has(button.swiper-arrow-left:not(:disabled))]:before:from-0%',
      '[&.is-overflow:has(button.swiper-arrow-left:not(:disabled))]:before:via-ing-neutral-800/70',
      '[&.is-overflow:has(button.swiper-arrow-left:not(:disabled))]:before:via-80%',
      '[&.is-overflow:has(button.swiper-arrow-left:not(:disabled))]:before:to-ing-neutral-800',
      '[&.is-overflow:has(button.swiper-arrow-left:not(:disabled))]:before:to-100%',
      //
      // AFTER
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:content-[""]',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:top-0',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:bottom-0',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:block',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:w-[50px]',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:absolute',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:z-[2]',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:sm:w-[240px]',
      '[&.is-overflow:has(button.swiper-arrow:not(:disabled))]:after:pointer-events-none',
      // GRADIENT TO RIGHT
      '[&.is-overflow:has(button.swiper-arrow-right:not(:disabled))]:after:bg-gradient-to-r',
      '[&.is-overflow:has(button.swiper-arrow-right:not(:disabled))]:after:from-transparent',
      '[&.is-overflow:has(button.swiper-arrow-right:not(:disabled))]:after:from-0%',
      '[&.is-overflow:has(button.swiper-arrow-right:not(:disabled))]:after:via-ing-neutral-800/70',
      '[&.is-overflow:has(button.swiper-arrow-right:not(:disabled))]:after:via-80%',
      '[&.is-overflow:has(button.swiper-arrow-right:not(:disabled))]:after:to-ing-neutral-800',
      '[&.is-overflow:has(button.swiper-arrow-right:not(:disabled))]:after:to-100%'
    ]

    const arrowCSS = [
      'cursor-pointer',
      'border-none',
      'rounded-none',
      'bg-transparent',
      'items-center',
      'justify-center',
      'absolute',
      'disabled:opacity-0',
      'disabled:pointer-events-none',
      'h-6',
      'w-6',
      'z-10',
      '[&_svg]:w-6',
      '[&_svg]:h-6',
      '[&_svg]:fill-ing-blue-400'
    ]

    return (
      <Splide
        ref={ref}
        className={cn(
          'relative',
          shadows && shadowsCSS,
          '[&.is-overflow:hover_.swiper-arrows]:opacity-100',
          '[&.is-overflow:has(button.swiper-arrow-left:not(:disabled))]:before:left-0',
          '[&.is-overflow:has(button.swiper-arrow-right:not(:disabled))]:after:right-0',
          className
        )}
        hasTrack={false}
        options={{
          gap: '1rem',
          type: 'slide',
          focus: 0,
          omitEnd: true,
          keyboard: true,
          ...options,
          classes: {
            // Add classes for pagination.
            pagination: cn(
              'hidden absolute left-0 right-0 px-4 md:flex items-center justify-center -bottom-8'
            ),
            page: cn(
              'inline-block rounded-full border-none h-2 w-2 opacity-70 px-0 py-0 cursor-pointer my-1 mx-1 bg-ing-neutral-600 [&.is-active]:bg-ing-blue-400'
            )
          }
        }}
        {...props}
      >
        <div
          className={cn([
            // DO NOT REMOVE, splide uses this class to show/hide arrows
            'splide__arrows',
            'swiper-arrows',
            'opacity-0'
          ])}
        >
          <button
            className={cn(
              'swiper-arrow',
              'swiper-arrow-left',
              'splide__arrow--prev',
              'top-[calc(50%_-_12px)]',
              arrowCSS
            )}
            type="button"
          >
            <ArrowIcon />
          </button>
          <button
            className={cn(
              'swiper-arrow',
              'swiper-arrow-right',
              'splide__arrow--next',
              'top-[calc(50%_-_12px)]',
              arrowCSS
            )}
            type="button"
          >
            <ArrowIcon />
          </button>
        </div>
        <div className={cn('splide__track', classes?.track)}>
          <div className={cn('splide__list', classes?.list)}>
            {children?.map((slide, index) => (
              <div className={cn('splide__slide', classes?.slide)} key={index}>
                {slide}
              </div>
            ))}
          </div>
        </div>
      </Splide>
    )
  }
)

Swiper.displayName = 'Swiper'
export default Swiper
