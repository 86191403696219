import axios, { AxiosError } from 'axios'

const ticketingAPI = axios.create({ baseURL: process.env.TICKETING_URL })

import { getUserData, handleRefreshToken } from '@Shared/helpers/auth'

ticketingAPI.interceptors.request.use((config) => {
  const { token } = getUserData()

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

ticketingAPI.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => handleRefreshToken(ticketingAPI, error)
)

export default ticketingAPI
