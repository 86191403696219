export const RATING_MAP = {
  l: {
    label: 'L',
    title: 'livre',
    description: 'Não há inadequações.',
    color: 'bg-[#00A651]'
  },
  10: {
    label: '10',
    title: '10 anos',
    description: 'Temas Sensíveis, Violência.',
    color: 'bg-[#0095DA]'
  },
  12: {
    label: '12',
    title: '12 anos',
    description: 'Linguagem Imprópria,Temas Sensíveis, Violência.',
    color: 'bg-[#FBC115]'
  },
  14: {
    label: '14',
    title: '14 anos',
    description:
      'Atos Criminosos, Conteúdo Sexual, Linguagem Imprópria, Temas Sensíveis, Violência.',
    color: 'bg-[#F58220]'
  },
  16: {
    label: '16',
    title: '16 anos',
    description:
      'Atos Criminosos, Conteúdo Sexual, Linguagem Imprópria, Nudez, Temas Sensíveis, Violência.',
    color: 'bg-[#EC1D25]'
  },
  18: {
    label: '18',
    title: '18 anos',
    description:
      'Atos Criminosos, Conteúdo Sexual, Linguagem Imprópria, Nudez, Temas Sensíveis, Violência.',
    color: 'bg-black'
  },
  default: {
    label: '?',
    title: '?',
    description: 'Consulte a classificação',
    color: 'bg-[#666]'
  }
}

export type RatingKeys = keyof (typeof RATING_MAP)['default']
