'use client'

import { useSearchParams } from 'next/navigation'
import { ReactNode, useEffect, useState } from 'react'
import { createContext } from 'use-context-selector'

import { DEFAULT_PARTNERSHIP } from '@Shared/constants/application'

type Partnership = {
  id: string
  images: any
  promotions?: any
  corporatives?: any
}

export type PartnershipContextProps = {
  children: ReactNode
}

type PartnershipContext = {
  partnership: Partnership
}

export const PartnershipContext = createContext({} as PartnershipContext)

export const PartnershipProvider = ({ children }: PartnershipContextProps) => {
  const searchParams = useSearchParams()
  const [partnership, setPartnership] = useState(DEFAULT_PARTNERSHIP)

  // update partnership when url param change
  useEffect(() => {
    const partnership_param = searchParams?.get('partnership')

    setPartnership((previousState) => {
      return partnership_param && previousState.id !== partnership_param
        ? { id: partnership_param?.split('?')?.[0], images: [] }
        : previousState
    })
  }, [searchParams])

  return (
    <PartnershipContext.Provider value={{ partnership }}>
      {children}
    </PartnershipContext.Provider>
  )
}
