import { ComponentProps } from 'react'

import { cn } from '@Shared/helpers/util'

type BasicProps = ComponentProps<'div'>

type ListProps = {
  length: number
  classes?: {
    list?: string
    item?: string
  }
}

const Basic = ({ className, ...props }: BasicProps) => {
  return (
    <div
      className={cn('animate-pulse bg-ing-neutral-500', className)}
      {...props}
    />
  )
}

const List = ({ classes, length }: ListProps) => {
  const elementList = new Array(length).fill(0)

  return (
    <div className={cn('flex', classes?.list)}>
      {elementList.map((_, index) => (
        <Basic
          className={cn(classes?.item)}
          key={index}
          data-testid="skeleton-list-element"
        />
      ))}
    </div>
  )
}

const Skeleton = {
  Basic,
  List
}
export default Skeleton
