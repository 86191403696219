import { useQuery } from '@tanstack/react-query'

import contentAPI from '@Services/content-api'

import useCity from '../use-city'
import usePartnership from '../use-partnership'

const queryKey = (
  cityId: string,
  partnershipId: string,
  slug?: string,
  limit?: number
) => {
  const key = ['collection', cityId, partnershipId, limit]
  return slug ? [...key, slug] : key
}

const fetchCollections = async (
  cityId: string,
  partnershipId = 'home',
  slug?: string,
  limit?: number
) => {
  const baseUrl = new URL(
    `/v0/carousel/${cityId}/partnership/${partnershipId}`,
    process.env.CONTENT_URL
  )

  const params = baseUrl.searchParams
  slug && params.set('carousels', slug)
  limit && params.set('limit', String(limit))

  const response = await contentAPI.get(baseUrl.href)
  const { data } = response

  return slug ? data[0] : data
}

type useCollectionQueryType = {
  slug: string
  options?: any
  limit?: number
}

export const useCollectionQuery = (args: useCollectionQueryType) => {
  const { options, limit, slug } = args
  const { city } = useCity()
  const partnership = usePartnership()

  return useQuery<any>({
    queryKey: queryKey(city.id, partnership.id, slug, limit),
    queryFn: () => fetchCollections(city.id, partnership.id, slug, limit),
    ...options
  })
}

/**
 * @param cityId
 * @param partnership
 * @param slug - Carousel slug
 * @returns - A single or a list of Carousels, defaults to list.
 */
export const useCollectionsQuery = (options?: any) => {
  const { city } = useCity()
  const partnership = usePartnership()

  return useQuery<any>({
    queryKey: queryKey(city.id, partnership.id),
    queryFn: () => fetchCollections(city.id, partnership.id),
    ...options
  })
}
