'use client'

import { ComponentProps } from 'react'

import { Event } from '@Schemas/domain/event'
import { SimplifiedTheaterWithSessionsByType } from '@Schemas/domain/theater'

import UOLAdContainer from '@Components/ad-banner/uol-ads'

type InterstitialBannerProps = ComponentProps<'div'> & {
  movie?: Event
  theater?: SimplifiedTheaterWithSessionsByType
}

const InterstitialBanner = ({
  movie,
  theater,
  ...props
}: InterstitialBannerProps) => {
  return (
    <div className="fixed top-0 z-[100]" {...props}>
      <UOLAdContainer
        id="div-gpt-ad-interstitial"
        movie={movie}
        theater={theater}
      />
    </div>
  )
}

export default InterstitialBanner
