'use client'

import { ComponentProps, useState } from 'react'
import Button from 'src/ui/button'

import { cn } from '@Shared/helpers/util'
import { usePageName } from '@Shared/hooks/use-page-name'

import { Trailer } from '@Schemas/domain/event'

import tracking from '@Services/tracking'

type TrailersProps = ComponentProps<'div'> & {
  trailers?: Trailer[]
  title?: string
  isOpen?: boolean
}

const Trailers = ({
  className,
  isOpen,
  trailers,
  title,
  ...props
}: TrailersProps) => {
  const pageName = usePageName()
  const [currentTrailer, setCurrentTrailer] = useState(0)

  const handleTrailerClick = (label) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: `Showtimes by ${pageName}`,
      event_action: 'Click on Trailer',
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })

    setCurrentTrailer(label)
  }

  return (
    <div {...props} className={cn('flex flex-col gap-6', className)}>
      {trailers?.length ? (
        <>
          {isOpen && <h2 className="mb-4 pr-10 text-2xl">{title}</h2>}
          {trailers.map(
            (trailer, index) =>
              currentTrailer === index && (
                <iframe
                  key={`video-${trailer.url}`}
                  src={`${trailer.embeddedUrl}?enablejsapi=1&origin=${process.env.BASE_URL}`}
                  title={`Trailer no youtube do filme ${title}`}
                  allowFullScreen={true}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                  referrerPolicy="no-referrer-when-downgrade"
                  className={cn('aspect-video size-full rounded-xl')}
                ></iframe>
              )
          )}
          {Boolean(trailers?.length > 1) && (
            <div className="flex flex-wrap gap-4">
              {trailers.map((trailer, index) => (
                <Button
                  key={`video-${index}-${trailer.url}`}
                  variant="outline"
                  onClick={() => handleTrailerClick(index)}
                >
                  Trailer {index + 1}
                </Button>
              ))}
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}

export default Trailers
