import { forwardRef } from 'react'
import Dialog from 'src/ui/dialog'

import { Event, Trailer } from '@Schemas/domain/event'

import Icon from '@Components/icon'
import Trailers from '@Components/trailers'

type MovieTrailerModalProps = {
  movie: Event
  trailer?: Trailer[]
}

const MovieTrailerModal = forwardRef<HTMLDivElement, MovieTrailerModalProps>(
  ({ movie, trailer, ...props }, ref) => (
    <Dialog.Content
      className="h-full max-h-full w-full overflow-y-auto rounded-none bg-ing-neutral-600 p-4 sm:h-min sm:rounded-xl sm:p-6 md:w-[738px] lg:w-[785px]"
      ref={ref}
      {...props}
    >
      <Dialog.Header className="h-auto bg-transparent lg:h-auto">
        <Dialog.Close asChild>
          <Icon
            src="/images/close.svg"
            className="absolute right-2 top-2 cursor-pointer [&_path]:fill-ing-neutral [&_svg]:h-7 [&_svg]:w-7"
          />
        </Dialog.Close>
      </Dialog.Header>
      <Trailers
        trailers={trailer || movie?.trailers}
        title={movie?.title}
        isOpen
      />
    </Dialog.Content>
  )
)
MovieTrailerModal.displayName = 'MovieTrailerModal'

export default MovieTrailerModal
